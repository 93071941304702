<template>
    <BT-Blade-Items
        addBladeName="supplier-payment-term"
        bladeName="supplier-payment-terms"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Name', value: 'paymentTermsName', title: 1 },
            { text: 'End Of Month', value: 'endOfMonth', subtitle: 1, bool: true },
            { text: 'Days To Pay', value: 'daysToPay', subtitle: 2 }]"
        navigation="supplier-payment-terms"
        title="Payment Terms"
        useServerPagination />
</template>

<script>
export default {
    name: 'Supplier-Payment-Terms'
}
</script>